import { Routes } from '@angular/router';

export const ROUTES: Routes = [
    {
        path: 'game',
        loadChildren: () => import('./pages/game/game.routes').then(m => m.GAME_ROUTES)
    },
    {
        path: 'legal',
        loadChildren: () => import('./pages/legal/legal.routes').then(m => m.LEGAL_ROUTES)
    },
    {
        path: '',
        loadComponent: () => import('./pages/public/landing/landing.component').then(m => m.LandingComponent)
    },
    {
        path: 'patreon-supporters',
        loadComponent: () => import('./pages/public/patreon-supporters/patreon-supporters.component').then(m => m.PatreonSupportersComponent)
    },
    {
        path: 'data-error',
        loadComponent: () => import('./pages/public/error401/error401.component').then(m => m.Error401Component)
    },
    {
        path: '**',
        loadComponent: () => import('./pages/public/error404/error404.component').then(m => m.Error404Component)
    }
];
