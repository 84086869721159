import { addTelemetryUsage, createContextManager, makePublicApi, monitor, checkUser, sanitize, createCustomerDataTrackerManager, storeContextManager, displayAlreadyInitializedError, deepClone, createTrackingConsentState } from '@datadog/browser-core';
import { Logger } from '../domain/logger';
import { buildCommonContext } from '../domain/contexts/commonContext';
import { createPreStartStrategy } from './preStartLogs';
const LOGS_STORAGE_KEY = 'logs';
export function makeLogsPublicApi(startLogsImpl) {
  const customerDataTrackerManager = createCustomerDataTrackerManager();
  const globalContextManager = createContextManager('global', {
    customerDataTracker: customerDataTrackerManager.getOrCreateTracker(2 /* CustomerDataType.GlobalContext */)
  });
  const userContextManager = createContextManager('user', {
    customerDataTracker: customerDataTrackerManager.getOrCreateTracker(1 /* CustomerDataType.User */),
    propertiesConfig: {
      id: {
        type: 'string'
      },
      name: {
        type: 'string'
      },
      email: {
        type: 'string'
      }
    }
  });
  const trackingConsentState = createTrackingConsentState();
  function getCommonContext() {
    return buildCommonContext(globalContextManager, userContextManager);
  }
  let strategy = createPreStartStrategy(getCommonContext, trackingConsentState, (initConfiguration, configuration) => {
    if (initConfiguration.storeContextsAcrossPages) {
      storeContextManager(configuration, globalContextManager, LOGS_STORAGE_KEY, 2 /* CustomerDataType.GlobalContext */);
      storeContextManager(configuration, userContextManager, LOGS_STORAGE_KEY, 1 /* CustomerDataType.User */);
    }
    const startLogsResult = startLogsImpl(initConfiguration, configuration, getCommonContext, trackingConsentState);
    strategy = createPostStartStrategy(initConfiguration, startLogsResult);
    return startLogsResult;
  });
  const customLoggers = {};
  const mainLogger = new Logger((...params) => strategy.handleLog(...params), customerDataTrackerManager.createDetachedTracker());
  return makePublicApi({
    logger: mainLogger,
    init: monitor(initConfiguration => strategy.init(initConfiguration)),
    setTrackingConsent: monitor(trackingConsent => {
      trackingConsentState.update(trackingConsent);
      addTelemetryUsage({
        feature: 'set-tracking-consent',
        tracking_consent: trackingConsent
      });
    }),
    getGlobalContext: monitor(() => globalContextManager.getContext()),
    setGlobalContext: monitor(context => globalContextManager.setContext(context)),
    setGlobalContextProperty: monitor((key, value) => globalContextManager.setContextProperty(key, value)),
    removeGlobalContextProperty: monitor(key => globalContextManager.removeContextProperty(key)),
    clearGlobalContext: monitor(() => globalContextManager.clearContext()),
    createLogger: monitor((name, conf = {}) => {
      customLoggers[name] = new Logger((...params) => strategy.handleLog(...params), customerDataTrackerManager.createDetachedTracker(), sanitize(name), conf.handler, conf.level, sanitize(conf.context));
      return customLoggers[name];
    }),
    getLogger: monitor(name => customLoggers[name]),
    getInitConfiguration: monitor(() => deepClone(strategy.initConfiguration)),
    getInternalContext: monitor(startTime => strategy.getInternalContext(startTime)),
    setUser: monitor(newUser => {
      if (checkUser(newUser)) {
        userContextManager.setContext(newUser);
      }
    }),
    getUser: monitor(() => userContextManager.getContext()),
    setUserProperty: monitor((key, property) => {
      userContextManager.setContextProperty(key, property);
    }),
    removeUserProperty: monitor(key => userContextManager.removeContextProperty(key)),
    clearUser: monitor(() => userContextManager.clearContext())
  });
}
function createPostStartStrategy(initConfiguration, startLogsResult) {
  return {
    init: initConfiguration => {
      displayAlreadyInitializedError('DD_LOGS', initConfiguration);
    },
    initConfiguration,
    ...startLogsResult
  };
}
